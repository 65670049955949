.transactionGroup {
  border-radius: 1rem;
  background: #fff;
  min-height: 100px;
  margin-bottom: 0;
  margin-top: 1.5rem;
  padding: 1rem;
  &__heading {
    color: #8d8e92;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__content {
    margin-top: 1rem;
  }
}
