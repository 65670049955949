.tag {
  border-radius: 0.8rem;
  padding: 1rem;
  background: #fff;
  width: 100%;
  min-height: 1rem;
  display: grid;
  grid-template-columns: 50px 1fr;
  position: relative;
  padding-right: 1.5rem;
  cursor: pointer;

  &__icon {
    width: 40px;
    height: 40px;
    background: #4438cb09;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  &__content {
    h3 {
      font-size: 0.93rem;
      font-weight: 500;
    }
    p {
      color: #1c213a;
      font-size: 0.8rem;
      margin-top: 0.1rem;
    }
  }
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}
