.transferDetails {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 1rem;
  padding-bottom: 4rem;
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__qrCode {
    margin: auto;
    width: 150px;
    margin-top: 2rem;
  }
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    h3 {
      color: #1c213a;
      font-family: Inter;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      background: 10;
    }
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__input {
    margin-top: 2rem;
  }
  &__form {
    &__item {
      margin-top: 1.5rem;
      label {
        color: #494b55;
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: inherit;
        display: block;
        padding-bottom: 0.3rem;
      }
      p {
        color: #8d8e92;
        text-align: right;
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.3rem;
      }

      &__input {
        display: grid;
        height: 50px;
        grid-template-columns: 1fr 60px;
        padding: 0.5rem;
        background: #e5e8ef;
        border-radius: 0.3125rem;
        input {
          width: 100%;
          outline: none;
          font-size: 1rem;
          padding: 0 1rem;
          border-radius: 0.3125rem;
          background: #e5e8ef;
          border: none;
        }
        button {
          background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
          color: #fff;
          font-family: inherit;
          cursor: pointer;
          border-radius: 0.3125rem;
        }
      }
    }
  }

  &__countdown {
    width: fit-content;
    text-align: center;
    h6 {
      font-size: 0.9rem;
      font-weight: 500;
    }
    p {
      font-size: 0.9rem;
      font-weight: 400;
    }
    &__expired {
      border-radius: 20px;
      font-family: inherit;
      padding: 0.3rem 0.7rem;
      margin-top: 0.2rem;
      text-transform: capitalize;
      font-size: 0.9rem;
      color: #e74c3c;
      background: #e74c3c09;
    }
  }

  &__warning {
    width: 100%;
    max-width: 270px;
    margin: auto;
    margin-top: 2rem;
    h3 {
      color: #1c213a;
      font-family: Inter;
      font-size: 1.05rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      margin-top: 0.22rem;
      text-align: center;
      b {
        color: #4438cb;
      }
    }
    p {
      color: rgb(107, 106, 106);
      font-family: Inter;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      margin-top: 0.22rem;
      text-align: center;
    }
  }
  &__actions {
    margin-top: 1.5rem;
    button {
      width: 100%;
      cursor: pointer;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      height: 50px;
      display: grid;
      place-content: center;
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: 1rem;
      z-index: 2;
      border-radius: 2.5rem;
      background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
      border: 1px solid linear-gradient(179deg, #4438cb 0%, #7039cb 100%) !important;
      position: relative;
    }
    &__redirect {
      background: #fff !important;
      color: #4438cb !important;
      border: 1px solid #4438cb;
    }

    &__complete {
      &:before {
        content: "";
        position: absolute;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        top: -5px;
        left: -5px;
        z-index: -1;
        border-radius: 3rem;
        background: linear-gradient(
          179deg,
          rgba(68, 56, 203, 0.25) 0%,
          rgba(112, 57, 203, 0.8) 100%
        );
        filter: blur(10px);
      }
    }
  }

  &__submit {
  }

  &__info {
    background: #cff4fc;
    border-radius: 0.5rem;
    padding: 1rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
    cursor: pointer;
    // display: grid;
    // grid-template-columns: 30px 1fr;
    display: flex;
    align-items: center;
    img {
      width: 25px;
    }

    p {
      color: #065161;
      margin-left: 0.2rem;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      -ms-word-break: break-all;
      word-break: break-all;

      /* Non standard for webkit */
      word-break: break-word;

      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
    b {
      font-size: 0.99rem;
    }
  }

  &__others {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    &__item {
      margin-top: 1rem;
      label {
        color: #494b55;
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: inherit;
        display: block;
        padding-bottom: 0.3rem;
      }
      &__content {
        display: flex;
        position: relative;
        input {
          width: 100%;
          outline: none;
          font-size: 1rem;
          padding: 0 1rem;
          border-radius: 0.3125rem;
          background: #e5e8ef;
          border: none;
          padding-left: 2.3rem;
          height: 50px;
        }
        img {
          width: 19px;
          left: 14px;
          position: absolute;
          top: 15px;
        }
      }
    }
  }
}
