.modal {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  max-width: 450px;
  margin: auto;
  height: 100vh;
  position: fixed;
  z-index: 8;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
  top: 0;
  left: 0;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__main {
    width: 100%;
    max-width: 450px;
    padding: 0 1rem;
    height: auto;
    margin: auto;
    margin-top: 20vh;
    margin-bottom: 20vh;
  }
  &__body {
    width: 100%;
    border-radius: 1.25rem;
    //padding: 1rem;
    background: #fff;
    animation: modal 0.2s ease-out 1;

    margin-top: 0.5rem;
    @keyframes modal {
      from {
        transform: scale(0.9);
      }
      to {
        transform: scale(1);
      }
    }
  }
  &__cancel {
    width: fit-content;
    margin-left: auto;
    img {
      width: 25px;
      cursor: pointer;
    }
  }
}
