@import "../../styles/variables";

.login {
  font-family: inherit;
  background: $col-primary;

  &__logo {
    height: calc(50vh - 100px);
    width: 100%;

    display: grid;
    place-content: center;

    // h2 {
    //   font-weight: 900;
    //   color: #fff;
    //   font-size: 3rem;
    // }
    img{
      width: 70px;
      // height: 50px;
      margin: auto;
    }
  }

  &__main {
    padding: 2rem;
    padding-bottom: 3rem;
    border-top-left-radius: 43px;
    border-top-right-radius: 43px;
    background: #fff;
    height: auto;
    min-height: calc(50vh + 100px);
    // padding-top: 4rem;

    label{
        display: block;
        margin-top: 1.5rem;
        font-size: .9rem;
        color: #777777;
    }
    input{
        display: block;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        outline: none;
        border: none;
        margin-top: .5rem;
        background: #F3F3F3;
        padding: 0 1rem;
        font-family: inherit;
        font-size: .9rem;
        font-weight: 400;

    }
  }

  &__form__error{
    color: #E16C00;
    font-size: .9rem;
    font-weight: 400;
    margin-top: 1rem;
  }

  &__form__submit{
    margin-top: 2rem;
  }
}
