@import "../../styles/variables";

.button {
  width: 100% !important;
  background: $col-primary;
  outline: none;
  border: none;
  height: 55px;
  border-radius: 8px;
  font-family: inherit;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  display: grid !important;
  place-content: center !important;
}
