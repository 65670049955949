.layout {
  font-family: "Inter";
  background: #1e1e1e;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  color: #000000;

  &__main {
    width: 100%;
    max-width: 450px;
    height: 100vh;
    overflow: auto;
    margin: 0 auto;
    background: #fff;
  }
}
