.whitelistItem {
  border-radius: 1rem;
  background: #fff;
  min-height: 100px;
  margin-bottom: 0;
  margin-top: 0.5rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  position: relative;
  &__active {
    border: 1px solid #9b27b06e;
  }
  &__activate {
    background: red;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;

    display: grid;
    place-content: center;
    background: rgba(255, 255, 255, 0.2);
    //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.9px);
    -webkit-backdrop-filter: blur(2.9px);
    border-radius: 1rem;

    button {
      padding: 0.7rem 1.5rem;
      //border: 1px solid #4438cb;
      background: transparent;
      border-radius: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      p {
        font-size: 1rem;
        color: #4438cb;
      }
    }
  }

  &__removeAsDefault {
    // height: 50px;
    // border-bottom-left-radius: 1rem;
    // border-bottom-right-radius: 1rem;
    //border-top: 1px solid #e8ebfb;
    font-family: inherit;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    background: transparent;
    color: #4438cb;
  }

  &__actions {
    height: 50px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    // grid-template-columns: 1fr 1fr;
    border-top: 1px solid #e8ebfb;
    button {
      border-left: 1px solid #e8ebfb;
      width: 100%;
      &:last-child {
        border-bottom-right-radius: 1rem;
      }
      &:first-child {
        border-bottom-left-radius: 1rem;
        border-left: 0 !important;
      }
    }
    &__remove,
    &__complete,
    &__setDefault {
      font-family: inherit;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      background: transparent;
      width: 100%;
    }

    &__complete,
    &__setDefault {
      //border-bottom-left-radius: 1rem;
    }

    &__remove {
      color: #cb3838;
    }
    &__setDefault {
      color: #4438cb;
    }
  }
  &__group {
    padding: 1rem;
    padding-bottom: 0rem;
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      img {
        width: 28px;
        margin-left: 0.3rem;
      }
      p {
        margin: 0;
        margin-top: 0.2rem;
        color: #1c213a;
        font-family: Inter;
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 500;

        b {
          color: #8d8e92;
          font-size: 0.8rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
