//fonts

$font-default:  'Inter', sans-serif;


$col-white: #ffffff;
$col-primary:   #4438CB;






