.referTag {
  border-radius: 0.8rem;
  padding: 1rem;
  background: #0e0199ea;
  width: 100%;
  min-height: 1rem;
  display: grid;
  grid-template-columns: 90px 1fr;
  position: relative;
  grid-gap: 1rem;
  padding-right: 1.5rem;

  &__image {
    width: 100%;
    object-fit: cover;
    display: grid;
    place-content: center;
  }

  &__content {
    h3 {
      font-size: 0.97rem;
      font-weight: 500;
      color: #ffffffef;
    }
    p {
      color: #ffffffc9;
      font-size: 0.8rem;
      margin-top: 0.2rem;
      margin-bottom: .5rem;
    }
    a {
      color: rgba(40, 241, 13, 0.822);
      font-weight: 600;
      font-size: 0.8rem;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}
