.notAvailable {
  padding:2rem 1rem;
  min-height: 100vh;
  background: #fff;
  //background: linear-gradient(180deg, #e2dfff 19.79%, #f3f2ff 100%);

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-left: 1rem;
    img {
      width: 40px;
    }
    h4 {
      color: #000;
      font-family: Inter;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.045rem;
      margin-left: 0.2rem;
    }
  }
  &__main {
    min-height: calc(100vh - 100px);
    display: grid;
    place-content: center;
    img {
      margin: auto;
      display: block;
      width: 130px;
      margin-bottom: .5rem;
      transform: translateX(-4px);
    }
    h1 {
      color: #1c213a;
      text-align: center;
      font-family: Inter;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    p {
      color: #1c213a;
      text-align: center;
      font-family: Inter;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      max-width: 400px;
      margin-top: 0.5rem;
    }
  }
}
