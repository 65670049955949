.transactionDetails {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 0rem;
  padding-bottom: 0rem;
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }
  &__main {
    padding: 2rem 1rem;
    padding-bottom: 4rem;
    background: #f3f3f5;
    @media print {
      body {
        background-color: red;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
    
    }
  }
  &__logo {
    text-align: center;
    display: block;
    margin-top: 2rem;
    margin-bottom: 0.1rem;
    p{
      color: rgb(107, 106, 106);
      font-family: Inter;
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.4;
      margin-top: 0rem;
      text-align: center;
    }
  }
  &__back,
  &__share {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  &__warning {
    width: 100%;
    max-width: 270px;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: -2rem;
    h3 {
      color: #1c213a;
      font-family: Inter;
      font-size: 1.05rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      margin-top: 0.22rem;
      text-align: center;
      b {
        color: #4438cb;
      }
    }
    p {
      color: rgb(107, 106, 106);
      font-family: Inter;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      margin-top: 0.22rem;
      text-align: center;
    }
  }

  // &__countdown {
  //   width: fit-content;
  //   text-align: center;
  //   margin-top: 1rem;
  //   h6 {
  //     font-size: 0.9rem;
  //     font-weight: 500;
  //   }
  //   p {
  //     font-size: 1rem;
  //     font-weight: 400;
  //   }
  //   &__expired {
  //     border-radius: 20px;
  //     font-family: inherit;
  //     padding: 0.3rem 0.7rem;
  //     margin-top: 0.2rem;
  //     text-transform: capitalize;
  //     font-size: 0.9rem;
  //     color: #e74c3c;
  //     background: #e74c3c09;
  //   }
  // }

  &__back {
    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__share {
    img {
      height: 20px;
    }
  }

  &__insight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0rem;

    &__amount {
      color: #1c213a;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    &__account {
      color: #1c213a;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0.1rem;
    }

    &__date {
      color: #727375;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0.1rem;
    }

    &__logoBox {
      position: relative;

      &__image {
        max-width: 50px;
        max-height: 50px;
        border-radius: 50%;
        object-fit: cover !important ;
        object-position: center center;
        border: 1px solid #ecebfa;
        //height: 100%;
      }

      &__logo {
        width: 50px;
        height: 50px;
        display: grid;
        place-content: center;
        border-radius: 50%;
        background: #ecebfa;
        h3 {
          color: #4438cb;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      &__arrow {
        width: 20px;
        height: 20px;
        //background: blue;
        //border-radius: 50%;
        position: absolute;
        display: grid;
        place-content: center;
        bottom: 0;
        right: 0;

        img {
          width: 20px;
          transform: rotate(180deg);
        }

        &__received {
          img {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  &__memo {
    padding: 1rem;
    border-radius: 0.625rem;
    background: #fff;
    margin-top: 4rem;
    margin-bottom: 0.3rem;
    h4 {
      color: #8d8e92;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p {
      color: #1c213a;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0.5rem;
    }
  }

  &__info {
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 0.625rem;
    background: #fff;
    margin-top: 0.3rem;
    cursor: pointer;

    &__sendDetails {
      margin-top: 2rem;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
      h4 {
        color: #8d8e92;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        color: #1c213a;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        word-break: break-all;
        text-align: right;
        margin-left: 0.5rem;
        // max-width: calc(100% - 100px);
      }
      &__status {
        // background: #ff0000;
        // background: #00c853;
        // background: #808080 ;
        //color: #fff !important;
        padding: 0.3rem 1rem;
        font-size: 0.75rem !important;
        border-radius: 50px;
      }
    }
  }
}
