.select {
  position: relative;

  &__selected {
    border-radius: 1.0625rem;
    background: rgba(38, 44, 190, 0.05);
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 50px;
    cursor: pointer;
    padding-right: 0.5rem;
    @media (max-width: 600px) {
      // height: 40px;
      //   padding: 0 0.2rem;
      border-radius: 0.8rem;
    }
    &__icon {
      width: 20px;
      @media (max-width: 600px) {
        // width: 20px;
      }
    }
    h4 {
      color: #1e1e1e;
      text-align: center;
      font-family: Inter;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 0.5rem;
      margin-right: 0.3rem;
      @media (max-width: 600px) {
        // margin-left: 0.3rem;
        // margin-right: 0.2rem;
        // font-size: 0.73rem;
      }
    }
    &__arrow {
      width: 18px;
      @media (max-width: 600px) {
        // width: 15px;
      }
    }
  }

  &__options {
    padding: 1rem;
    position: absolute;
    border-radius: 0.625rem;
    background: #fff;
    box-shadow: 11px 14px 40px 0px rgba(0, 0, 0, 0.1);
    left: 0;
    top: calc(100% - 1px);
    min-width: 115px;
    z-index: 2;
    animation: select-options 0.2s ease-out 1;
    cursor: pointer;
    @keyframes select-options {
      from {
        transform: scale(0.9);
      }
      to {
        transform: scale(1);
      }
    }
  }
  &__option {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    img {
      width: 20px;
      // @media (max-width: 600px) {
      //   width: 18px;
      // }
    }
    &:nth-child(1) {
      margin-top: 0rem;
    }
    p {
      color: #1e1e1e;
      text-align: center;
      font-family: Inter;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 0.5rem;
      @media (max-width: 600px) {
        // margin-left: 0.3rem;
        // font-size: 0.73rem;
      }
    }
  }
}
