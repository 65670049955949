.approveTransfer {
  padding: 3rem 1rem;
  border-radius: 1.25rem;

  &__loader {
    min-height: 300px;
    display: grid;
    place-content: center;
    p {
      color: #494b55;
      font-family: Inter;
      font-size: 0.875rem;
    }
  }
  &__heading {
    color: #1c213a;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0rem;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__form {
    margin-top: 2rem;

    &__heading {
      width: 100%;
      display: grid;
      grid-template-columns: 120px 1fr;
      margin-bottom: 0.3rem;
      p {
        color: #494b55;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &__input {
      display: flex;
      height: 50px;
      width: 100%;
      border-radius: 0.3125rem;
      background: rgba(38, 44, 190, 0.05);
      // @media (max-width: 600px) {
      //   height: 50px;
      //   border-radius: 0.8rem;
      // }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 1.1rem;
        padding-right: 1rem;
        padding-left: 0.5rem;
        background: transparent;
        // @media (max-width: 600px) {
        //   width: 100px;
        // }
      }
      &__line {
        width: 2px;
        height: calc(100% - 34px);
        margin-top: 17px;
        background: #3e434791;
        // @media (max-width: 600px) {
        //   height: calc(100% - 30px);
        //   margin-top: 15px;
        // }
      }
    }
  }
  // &__input {
  //   margin-top: 1rem;
  //   label {
  //     color: #494b55;
  //     font-family: Inter;
  //     font-size: 0.875rem;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: normal;
  //   }
  //   input[type="number"],
  //   textarea {
  //     border-radius: 0.3125rem;
  //     background: #eff0f3;
  //     width: 100%;
  //     height: 50px;
  //     font-family: inherit;
  //     font-size: 0.9rem;
  //     color: #1c213a;
  //     outline: none;
  //     border: none;
  //     padding: 0 1rem;
  //     margin-top: 0.5rem;
  //   }
  //   textarea {
  //     height: 100px;
  //     padding-top: 1rem;
  //   }
  // }
  &__submit {
    border-radius: 1.875rem;
    background: #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 2.5rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    display: grid;
    place-content: center;
    @media (max-width: 350px) {
      font-size: 0.9rem;
    }

    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }
}
