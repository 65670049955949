@import "../../styles/variables";

.loader {
  width: 100%;
  max-width: 450px;
  margin: auto;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.9px);
  -webkit-backdrop-filter: blur(12.9px);
  display: grid;
  place-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__main {
    display: flex;
    align-items: center;
    h4 {
      font-size: 1rem;
      font-weight: 500;
      color: #4438cb;
    }
  }
}
