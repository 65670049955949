.earnMoney {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 0rem;

  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__content {
    padding: 0 1rem;
  }

  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }

  &__groupImage {
    max-width: 340px;
    display: block;
    margin: auto;
    margin-top: 0rem;
  }

  &__desc {
    margin-top: 0.2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  &__points {
    margin-top: 2rem;
  }
  &__point {
    display: grid;
    grid-template-columns: 50px 1fr 60px;
    align-items: center;
    border-bottom: 1px solid #1c213a1a;
    padding: 1rem 0rem;
    cursor: pointer;
    &__icon {
      width: 40px;
      transform: translateY(5px);

      img {
        width: 100%;
      }
    }
    &__value {
      margin-left: auto;
      p {
        color: #1c213a;
        font-size: 0.95rem;
        display: flex;
        align-items: center;
        span{
          display: block;
          cursor: pointer;
          &:last-child{
            margin-top: 3px;
          }
        }
      }
    }

    &__desc {
      h3 {
        margin-top: 0rem;
        color: #1c213a;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        margin-top: 0.3rem;
        color: #1c213a;
        font-family: Inter;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__create {
    border-radius: 1.875rem;
    background: #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 3rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    display: grid;
    place-content: center;
    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }

  &__authorization {
    &__empty {
      min-height: 200px;
      display: grid;
      grid-template-columns: 1fr;
      place-content: center;
      padding: 0 1.5rem;
      padding-bottom: 1.5rem;

      &__content {
        width: fit-content;
        margin: auto;
      }
      img {
        width: 80px;
        display: block;
        // margin-left: 20px;
        margin: auto;
      }
      p {
        margin-top: 0.5rem;
        color: #7c7c80;
        font-family: Inter;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
    }
  }
}
