.rates {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 0rem;
  padding-bottom: 1rem;
  &__content {
    padding: 0 1rem;
    min-height: calc(100vh - 160px);
  }
  &__items {
    margin-top: 2rem;
  }
  &__item {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    &:nth-child(even) {
      background: #80808009;
    }
    @media (max-width: 400px) {
      padding: 1rem 0.5rem;
    }
    &__content {
      display: flex;
      align-items: center;
    }
    &__fromIcon,
    &__toIcon {
      width: 17px;
    }

    &__fromCurrency,
    &__toCurrency {
      margin-left: 0.2rem;
      font-size: 0.9rem;
    }

    &__arrow {
      width: 20px;
      margin: 0 0.5rem;
    }
    h3 {
      font-weight: normal;
      font-size: 0.9rem;
      margin-left: 0.5rem;
    }
    button {
      font-size: 0.8rem;
      font-family: inherit;
      padding: 0.5rem 0rem;
      border-radius: 2rem;
      color: blue;
      border: 1px solid blue;
      cursor: pointer;
      background: transparent;
      width: 100px;
    }
  }

  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__loading {
    text-align: center;
    padding: 2rem 0;
    color: #1c213a;
    font-size: 0.9rem;
    margin: auto;
    margin-top: 3rem;
    width: fit-content;
    display: flex;
    align-items: center;
    span {
      margin-right: 0rem;
      color: #4438cb;
      margin-top: -0.2rem;
    }
  }
}
