.filter {
  background: #f3f3f5;
  padding: 0;
  border-radius: 1.25rem;
  &__heading {
    padding: 2rem 1rem;
    background: #fff;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    img {
      width: 8px;
      margin-bottom: 0.1rem;
      cursor: pointer;
    }
    h3 {
      color: #1c213a;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      margin-top: 0rem;
    }
    p {
      color: #1c213a;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__inputs {
    padding: 1rem;
    padding-bottom: 2rem;
  }
  &__input {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 30px 60px 1fr;
    align-items: center;
    background: #fff;
    border-radius: 0.5rem;
    padding: 1rem 1rem;
    &__image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: auto;
      background: linear-gradient(
        345deg,
        rgba(165, 126, 228, 0.87) 14.06%,
        #cb92b1 50.52%,
        rgba(203, 146, 177, 0.65) 80.21%
      );
      // mix-blend-mode: darken;
    }
    p {
      color: #1c213a;
      font-family: Inter;
      font-size: 0.95rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    input {
      border-radius: 0.3125rem;
      background: #eff0f3;
      height: 15px;
      font-family: inherit;
      font-size: 0.9rem;
      color: #1c213a;
      outline: none;
      border: none;
      accent-color: #4438cb;
    }
  }
  &__submit {
    border-radius: 1.875rem;
    background: #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.5rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    @media (max-width: 350px) {
      font-size: 0.9rem;
    }

    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }
}
