.createWhitelist {
  padding: 3rem 1rem;
  border-radius: 1.25rem;

  &__loader {
    min-height: 300px;
    display: grid;
    place-content: center;
    p {
      color: #494b55;
      font-family: Inter;
      font-size: 0.875rem;
    }
  }
  &__heading {
    color: #1c213a;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0rem;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__form {
    margin-top: 2rem;

    &__heading {
      width: 100%;
      display: grid;
      grid-template-columns: 120px 1fr;
      margin-bottom: 0.3rem;
      p {
        color: #494b55;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &__item {
      margin-top: 1rem;

      label {
        color: #494b55;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: block;
        margin-bottom: 0.3rem;
      }

      &__paste {
        width: 100%;
        background: red;
        height: 50px;
        padding: 0.5rem;
        display: grid;
        grid-template-columns: 1fr 50px;
        border-radius: 0.3125rem;
        background: #e5e8ef;

        input {
          width: 100%;
          background: #e5e8ef;
          height: 100%;
          background: transparent;
          padding: 0 1rem;
          font-family: inherit;
          font-size: 0.9rem;
          border-radius: 0.1875rem;
          border: none;
          outline: none;
        }
        &__icon {
          display: grid;
          place-content: center;
          border-radius: 0.1875rem;
          background: #fff;
          cursor: pointer;

          img {
            width: 15px;
          }
        }
      }
    }
  }

  &__submit {
    border-radius: 1.875rem;
    background: #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 2.5rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    display: grid;
    place-content: center;
    @media (max-width: 350px) {
      font-size: 0.9rem;
    }

    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }
}
