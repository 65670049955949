.more {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 0rem;
  padding-bottom: 1rem;
  &__content {
    padding: 0 1rem;
    min-height: calc(100vh - 160px);
    &__links {
      margin-top: 2rem;
    }
    &__link {
      border-radius: 0.375rem;
      border: 1px solid #e8ebfb;
      background: #fff;
      box-shadow: 0px 25px 50px -15px rgba(3, 4, 10, 0.08);
      margin-top: 0.5rem;
      padding: 1rem;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      img {
        width: 9px;
      }

      &__desc {
        display: flex;
        align-items: center;
        h3 {
          color: #1c213a;
          text-align: center;
          font-size: .95rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        img {
          width: 25px;
          margin-right: 0.5rem;
        }
      }
    }
  }
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
