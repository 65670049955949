.home {
  font-family: Inter;
  padding-bottom: 3rem;
  position: relative;
  background: linear-gradient(180deg, #e2dfff 19.79%, #f3f2ff 100%);
  min-height: 100vh;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    img {
      width: 30px;
    }
    h4 {
      color: #000;
      font-family: Inter;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.045rem;
      margin-left: 0.2rem;
    }
  }
  &__cards {
    width: 100%;

    @media (min-width: 600px) {
      display: none;
    }
    &__desktop {
      display: none;
      @media (min-width: 600px) {
        display: block;
        width: 100%;
        max-width: 900px;
        margin: auto;
        margin-top: 2rem;
        padding: 0 1rem;
      }
    }
  }

  &__nav {
    background: #cac6f7;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    // padding-top: 3rem;

    &__items {
      height: inherit;
      display: flex;
    }
    &__item {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 1rem;
      border-bottom: 4px solid transparent;
      p {
        color: rgba(0, 0, 0, 0.5);
        font-family: Inter;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &__active {
        border-bottom: 4px solid #000;
        p {
          color: #000;
        }
      }
    }
  }

  &__mobileNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
      }
      h4 {
        color: #000;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.04rem;
        margin-left: 0.5rem;
      }
    }

    &__menu {
      position: relative;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #d1cefc;
      display: grid;
      place-content: center;

      &__icon {
        width: 23px;
      }

      &__items {
        position: absolute;
        border-radius: 0.625rem;
        background: #fff;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.15);
        padding: 1rem 1rem;
        padding-top: 0.5rem;
        right: 1rem;
        top: 1rem;
        min-width: 140px;

        animation: show-menu 0.2s ease-out 1;
        @keyframes show-menu {
          from {
            transform: scale(0.9);
          }
          to {
            transform: scale(1);
          }
        }
      }
      &__item {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        p {
          color: #101010;
          font-family: Inter;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        img {
          width: 17px;
        }
      }
    }
  }
  &__main {
    padding: 1rem;
    padding-top: 0;
    max-width: 650px;
    margin: 0 auto;

    &__heading {
      color: #1c213a;
      text-align: center;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      max-width: 360px;
      margin: auto;
      font-family: Inter;
      @media (max-width: 360px) {
        font-size: 1.55rem;
      }
      @media (min-width: 480px) {
        font-size: 2.3rem;
        max-width: 450px;
      }
      @media (min-width: 700px) {
        font-size: 3rem;
        font-weight: 800;
        max-width: 600px;
      }
      b {
        color: #4438cb;
      }
    }
    &__desc {
      color: #1c213a;
      text-align: center;
      font-size: 0.95rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 350px;
      margin: auto;
      margin-top: 1rem;
      font-family: Inter;

      @media (max-width: 350px) {
        font-size: 0.9rem;
      }
      @media (min-width: 450px) {
        font-size: 1rem;
        max-width: 450px;
      }
      @media (min-width: 600px) {
        font-size: 1.2rem;
        font-weight: 400;
        max-width: 600px;
      }
    }
    &__login {
      border-radius: 4.875rem;
      background: #4438cb;
      height: 3.2rem;
      width: 100%;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 2.5rem;
      font-style: normal;
      font-family: Inter;
      cursor: pointer;
      @media (max-width: 350px) {
        font-size: 0.9rem;
      }
      @media (min-width: 450px) {
        font-size: 1.1rem;
      }
      @media (min-width: 600px) {
        font-size: 1.2rem;
        height: 4rem;
      }
    }

    &__mobile {
      margin-top: 2.5rem;

      &__heading {
        color: #1c213a;
        text-align: center;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (min-width: 450px) {
          font-size: 1rem;
        }
      }
      &__links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background: #212121;
        border-radius: 100px;
        margin-top: 1rem;
        padding: 1rem;
      }

      &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: .1rem 0;
        // width: fit-content;
        // margin: auto;
        p {
          color: #fff;
          font-size: 0.8rem;
          margin-left: 0.5rem;
        }
        img {
          width: 13px;
        }
        &:last-child {
          border-left: 1.5px solid #616161;
          img {
            width: 15px;
          }
        }
      }
    }
  }

  &__footer {
    height: 50px;
    border-top: 1px solid #d4d6e4;
    border-bottom: 1px solid #d4d6e4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    p {
      color: #4438cb;
      font-size: 0.875rem;
    }

    max-width: calc(650px - 2.5rem);
    margin: 0 auto;
    margin-top: 2rem;
  }
}
