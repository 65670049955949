.kyc {
  min-height: 100vh;

  &__main {
    min-height: 100vh;
    display: grid;
    place-content: center;
    p {
        color: #1c213a;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
  }
}
