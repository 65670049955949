.accountDetails {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 1rem;
  padding-bottom: 4rem;
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__details {
    margin-top: 2rem;

    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__currency {
        display: flex;
        align-items: center;
        img {
          width: 20px;
        }
        h4 {
          color: #1c213a;
          font-size: 0.85rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 0.3rem;
        }
      }

      &__copy {
        display: flex;
        align-items: center;
        cursor: pointer;
        p {
          color: #1c213a;
          font-size: 0.85rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-right: 0.2rem;
        }
        img {
          width: 18px;
        }
      }
    }
  }

  &__detail {
    border-radius: 1rem;
    background: #fff;
    padding: 1rem 1.5rem;
    margin-top: 0.3rem;
    cursor: pointer;

    h4 {
      color: #1c213a;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p {
      color: #8d8e92;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0.4rem;
    }
  }

  &__info {
    background: #cff4fc;
    border-radius: 0.5rem;
    padding: 1rem 1rem;
    margin-top: 6.5rem;
    cursor: pointer;
    // display: grid;
    // grid-template-columns: 30px 1fr;
    display: flex;
    align-items: center;
    img {
      width: 25px;
    }

    p {
      color: #065161;
      font-size: 0.8rem;
      margin-left: 0.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      -ms-word-break: break-all;
      word-break: break-all;

      /* Non standard for webkit */
      word-break: break-word;

      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
  }
}
