.bottomNav {
  position: sticky;
  bottom: 1rem;
  width: inherit;
  z-index: 3;

  &__main {
    display: flex;
    justify-content: space-evenly;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    //grid-gap: 0.1rem;
    align-items: center;
    background: #fff;
    box-shadow: 0px 8px 36px 1px rgba(0, 0, 0, 0.08);
    border-radius: 1rem;
    padding: 1.3rem 0rem;
    margin: 1rem;
    margin-top: 1rem;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 25px;
    &:nth-child(2),
    &:nth-child(3) {
      .bottomNav__item__icon {
        width: 33px !important;
        height: 33px !important;
      }
    }
    &__icon {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      display: grid;
      place-content: center;
      //background: #eff2fb;

      &__active {
        background: #eff2fb;
      }
      &__more {
        width: 24px;
        height: 24px;
        display: grid;
        place-content: center;
        border-radius: 50%;
        border: 2px solid #a5a5a5;
        &__active {
          border: 2px solid #4438cb;
        }
      }
    }
    p {
      color: #a5a5a5;
      font-family: Inter;
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      margin: 0rem;
      width: 70px;
      // transform: translateY(-4px);
    }
    img {
      width: 26px;
    }
    picture {
      width: 26px;
      //  background: pink;
      //height: 26px;
    }
    svg {
      width: 100%;
      height: auto;
      // transform: translateY(5px);
      margin-bottom: -0.2rem;
    }
    &:nth-child(1) {
      margin-left: -1rem;
      margin-top: -0.1rem;
      // p {
      //   transform: translateY(-1px);
      // }
    }
    &:nth-child(2),
    &:nth-child(3) {
      img,
      picture {
        width: 23px;
      }
      p {
        margin-top: 0.0025rem;
      }
    }

    &:nth-child(4) {
      margin-left: 0.1rem;
      img,
      picture {
        width: 20px;
      }
      p {
        margin-top: 0.00035rem;
      }
    }
  }
}
