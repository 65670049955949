.authorizations {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 0rem;
  &__content {
    padding: 0 1rem;
    min-height: calc(100vh - 160px);
  }
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }

  &__header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    &__profile {
      //   background: red;

      &__box {
        display: flex;
        align-items: center;
      }
      &__address {
        margin-right: 0.3rem;
        color: #1c213a;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      cursor: pointer;
      // width: 40px;
      // height: 40px;
      // position: relative;
      &__image {
        // width: inherit;
        // height: inherit;
        // border-radius: 50%;
        // background: #fff;
        // display: grid;
        // place-content: center;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &__tag {
        position: absolute;
        top: 0px;
        right: -5px;
      }
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }

  &__desc,
  &__desc__more__content {
    margin-top: 0.2rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: #1c213a;
    color: #1c213a;

    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      cursor: pointer;
      color: blue;
    }
    &__more {
      width: 100%;
      overflow: hidden;
      max-height: 0;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      box-sizing: border-box;
      padding-top: 0.3rem;
      &__open {
        max-height: 300px;
      }
    }
  }

  &__items {
    margin-top: 2rem;
  }

  &__create {
    border-radius: 1.875rem;
    background: #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 5rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    display: grid;
    place-content: center;
    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }

  &__authorization {
    &__empty {
      min-height: 200px;
      display: grid;
      grid-template-columns: 1fr;
      place-content: center;
      padding: 0 1.5rem;
      padding-bottom: 1.5rem;

      &__content {
        width: fit-content;
        margin: auto;
      }
      img {
        width: 80px;
        display: block;
        // margin-left: 20px;
        margin: auto;
      }
      p {
        margin-top: 0.5rem;
        color: #7c7c80;
        font-family: Inter;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
    }
  }
}
