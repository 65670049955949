.processing {
  padding: 1rem;
  min-height: 100vh;
  background: linear-gradient(180deg, #e2dfff 19.79%, #f3f2ff 100%);

  &__main {
    min-height: calc(100vh - 100px);
    display: grid;
    place-content: center;

    img {
      width: 100px;
      margin: auto;
      display: block;
    }

    h3 {
      text-align: center;
      color: #1c213a;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      font-family: Inter;
      max-width: 350px;
      margin-top: 0.2rem;
      color: #1c213a;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__menu {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: grid;
    place-content: center;
    margin-left: auto;

    &__icon {
      width: 23px;
    }

    &__items {
      position: absolute;
      border-radius: 0.625rem;
      background: #fff;
      box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.15);
      padding: 1rem 1rem;
      padding-top: 0.5rem;
      right: 1rem;
      top: 1rem;
      min-width: 170px;

      animation: show-menu 0.2s ease-out 1;
      @keyframes show-menu {
        from {
          transform: scale(0.9);
        }
        to {
          transform: scale(1);
        }
      }
    }
    &__item {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;

      p {
        color: #a5a5a5;
        font-family: Inter;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 0.5rem;
      }
      img {
        width: 17px;
      }
      &:last-child {
        margin-top: 1.2rem;
        img {
          width: 19px;
        }
      }
    }
  }
}
