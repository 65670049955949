.transaction {
  display: grid;
  grid-template-columns: 40px 1fr 100px;
  align-items: center;
  grid-gap: 1rem;

  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
  cursor: pointer;
  margin-top: 0rem;
  //updated
  padding: 0.6rem 0;

  &__icon {
    img {
      max-width: 38px;
      max-height: 38px;
      border-radius: 50%;
      object-fit: cover !important ;
      object-position: center center;
      border: 1px solid #ecebfa;
      //height: 100%;
    }
  }
  &__iconBox {
    position: relative;
  }
  &__iconBox__arrow,
  &__logoBox__arrow {
    width: 18px;
    height: 18px;
    //background: blue;
    //border-radius: 50%;
    position: absolute;
    display: grid;
    place-content: center;
    bottom: 0;
    right: 0;

    img {
      width: 18px;
      transform: rotate(180deg);
    }

    &__received {
      img {
        transform: rotate(0deg);
      }
    }
  }

  &__logoBox {
    position: relative;

    &__logo {
      width: 40px;
      height: 40px;
      display: grid;
      place-content: center;
      border-radius: 50%;
      background: #ecebfa;
      h3 {
        color: #4438cb;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    &__arrow {
    }
  }

  &__desc {
    h4 {
      margin: 0;
      color: #1c213a;
      font-family: Inter;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
    }
    p {
      margin: 0;
      margin-top: 0.2rem;
      color: #8d8e92;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
    }
    button {
      border-radius: 20px;
      font-family: inherit;
      padding: 0.1rem 0.5rem;
      margin-top: 0.2rem;
      text-transform: capitalize;
      // color:  #27AE60 ;
      // background: #27ae5f09;
      // color: #333333;
      // background: #33333309;
      // color: #E74C3C;
      // background: #E74C3C09;
      // color: #ffc400 !important;
      // background: #ffc40009 !important;
    }
  }
  &__amount {
    display: flex;
    align-items: center;
    margin-left: auto;

    color: #1c213a;
    text-align: right;
    font-family: Inter;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;

    span {
      display: block;
      // background: red;
      // &:nth-child(2){
      //     margin-top: .2rem;
      //     width: 13px;
      // }
      &:nth-child(1) {
        margin-top: -0.1rem;
      }
    }
  }
}
