.select {
  position: relative;
  width: 100%;

  &__inputBox {
    width: 100%;
    background: red;
    height: 50px;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 50px;
    border-radius: 0.3125rem;
    background: #e5e8ef;

    &__input {
      width: 100%;
      background: #e5e8ef;
      height: 100%;
      background: transparent;
      padding: 0 1rem;
      font-family: inherit;
      font-size: 0.9rem;
      border-radius: 0.1875rem;
      border: none;
      outline: none;
    }
    &__arrow {
      display: grid;
      place-content: center;
      border-radius: 0.1875rem;
      background: #fff;
      cursor: pointer;

      img {
        width: 15px;
      }
    }
  }

  &__options {
    padding: 0 1rem;
    position: absolute;
    border-radius: 0.1875rem;
    background: #fff;
    box-shadow: 0px 25px 50px -15px rgba(3, 4, 10, 0.25);
    left: 0;
    top: calc(100% + 5px);
    width: 100%;
    min-height: 50px;
    z-index: 3;
    animation: select-options 0.2s ease-out 1;
    cursor: pointer;
    max-height: 200px;
    overflow-y: auto;
    @keyframes select-options {
      from {
        transform: translateY(10px);
      }
      to {
        transform: translateY(0px);
      }
    }
  }
  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #f1f2f6;
    img {
      width: 25px;
      margin-right: 0.3rem;
    }
    &:first-child {
      // padding-top: 0rem;
      border-top: 0;
    }
    // &:last-child{
    //   border-b
    // }
    p {
      color: #1c213a;
      text-align: right;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 0.5rem;
      @media (max-width: 600px) {
        // margin-left: 0.3rem;
        // font-size: 0.73rem;
      }
    }

    &__token {
      display: flex;
      align-items: center;
      p {
        color: #1c213a;
        text-align: right;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 0.01rem;
        margin-right: 0.3rem;
      }
    }
  }
}
