.invite {
  padding: 3rem 1rem;
  border-radius: 1.25rem;
  &__heading {
    color: #1c213a;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0rem;
    text-align: center;
  }
  &__desc,
  &__loading {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 0.3rem;
  }
  &__loading {
    margin-top: 2rem;
  }

  &__actions {
    margin-top: 2rem;
  }
  &__input {
    display: grid;
    height: 50px;
    grid-template-columns: 1fr 60px;
    padding: 0.5rem;
    background: #e5e8ef;
    border-radius: 0.3125rem;
    margin-top: 2rem;
    input {
      width: 100%;
      outline: none;
      font-size: 1rem;
      padding: 0 1rem;
      border-radius: 0.3125rem;
      background: #e5e8ef;
      border: none;
    }
    button {
      background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
      color: #fff;
      font-family: inherit;
      cursor: pointer;
      border-radius: 0.3125rem;
    }
  }
  &__submit {
    border-radius: 1.875rem;
    background: #4438cb;
    border: 1px solid #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    @media (max-width: 350px) {
      font-size: 0.9rem;
    }
  }

  &__cancel {
    border-radius: 1.875rem;
    background: transparent;
    border: 1px solid #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #4438cb;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    @media (max-width: 350px) {
      font-size: 0.9rem;
    }
  }
}
