.verificationStep {
  margin-top: .5rem;
  background: #fcfcfd;
  border: 1px solid #dee5ed;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  min-height: 100px;
  padding: 1.5rem 1rem;
  outline: none;
  padding-top: 0.5rem;

  &__heading {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &__completed {
    border-radius: 20px;
    font-family: inherit;
    padding: 0.1rem 0.5rem;
    text-transform: capitalize;
    color: #27ae60;
    height: 30px;
    background: #27ae5f09;
    margin-top: 1rem !important;
    width: fit-content;
    font-size: 0.8rem;
  }

  &__item {
    margin-top: 0.5rem;
  }

  h6 {
    font-weight: 500;
    font-size: 0.75rem;
  }
  p,
  a {
    // font-weight: lighter;
    font-size: 0.8rem;
    margin-top: 0.2rem;
    font-weight: 400;
  }
  a {
    text-decoration: underline !important;
    cursor: pointer;
    // color: teal;
  }

  &__update {
    display: block;
    outline: none;
    border: none;
    width: auto;
    height: 30px;
    background: transparent;
    border: 1px solid #4438cb;
    color: #4438cb;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 60px;
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }
}
