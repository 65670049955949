.registration {
  padding: 1rem;
  font-family: Inter;
  min-height: 100vh;
  padding-bottom: 2rem;
  &__heading {
    margin-top: 3rem;
    color: #1c213a;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__input {
    margin-top: 2rem;
    input {
      border-radius: 0.3125rem;
      background: #e5e8ef;
      width: 100%;
      height: 50px;
      font-family: inherit;
      font-size: 0.9rem;
      color: #1c213a;
      outline: none;
      border: none;
      padding: 0 1rem;
    }
  }
  &__submit {
    border-radius: 1.875rem;
    background: #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 3rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    display: grid;
    place-content: center;
    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }

  &__checks {
    margin-top: 2rem;
  }
  &__check {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: flex-start;
    margin-top: 1rem;
    input {
      accent-color: #4438cb;
    }
    p {
      color: #5b5a66;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    a {
      color: blue;
    }
  }

  &__mobile {
    margin-top: 11.5rem;

    &__heading {
      color: #1c213a;
      text-align: center;
      font-size: 0.83rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &__links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: #212121;
      border-radius: 100px;
      margin-top: 0.5rem;
      padding: 1rem;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // padding: .1rem 0;
      // width: fit-content;
      // margin: auto;
      p {
        color: #fff;
        font-size: 0.8rem;
        margin-left: 0.5rem;
      }
      img {
        width: 13px;
      }
      &:first-child{
        img{
          transform: translateY(-1px);
        }
      }
      &:last-child {
        border-left: 1.5px solid #616161;
        img {
          width: 15px;
        }
      }
    }
  }
}
