.home {
  background: #f3f3f5;
  min-height: 100vh;
  padding-bottom: 1rem;

  &__content {
    min-height: calc(100vh - 160px);
  }
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    padding-top: 2rem;

    &__logo {
      width: 50px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center top;
    }

    &__authorizations {
      border-radius: 15px;
      background: #4438cb;
      padding: 0.7rem 1rem;
      color: #fff;
      font-size: 0.8rem;
      font-weight: 500;
      font-style: normal;
      font-family: Inter;
      cursor: pointer;
      display: grid;
      place-content: center;
      @media (max-width: 350px) {
        font-size: 0.9rem;
      }
    }

    &__menu {
      position: relative;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #fff;
      display: grid;
      place-content: center;

      &__icon {
        width: 23px;
      }

      &__items {
        position: absolute;
        border-radius: 0.625rem;
        background: #fff;
        box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.15);
        padding: 1rem 1rem;
        padding-top: 0.5rem;
        right: 1rem;
        top: 1rem;
        min-width: 170px;

        animation: show-menu 0.2s ease-out 1;
        @keyframes show-menu {
          from {
            transform: scale(0.9);
          }
          to {
            transform: scale(1);
          }
        }
      }
      &__item {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;

        p {
          color: #a5a5a5;
          font-family: Inter;
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 0.5rem;
        }
        img {
          width: 17px;
        }
        &:last-child {
          margin-top: 1.2rem;
          img {
            width: 19px;
          }
        }
      }
    }
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;

    &__content {
      min-width: 150px;
      height: 30px;
      margin: 0 0.5rem;
      border-radius: 1.25rem;
      border: 1px solid rgba(28, 33, 58, 0.15);
      background: #fff;
      display: grid;
      place-content: center;

      p {
        color: #1c213a;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0 1rem;
      }
    }

    &__arrow {
      border-radius: 1.25rem;
      border: 1px solid rgba(28, 33, 58, 0.15);
      background: #fff;
      width: 30px;
      height: 30px;
      display: grid;
      place-content: center;
      cursor: pointer;
      img {
        width: 10px;
      }
      &:first-child {
        img {
          margin-left: -10%;
        }
      }
      &:last-child {
        img {
          margin-left: 10%;
        }
      }
    }
  }

  &__cards {
    margin-top: 1rem;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding: 0 0.8rem;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    //padding-right: 70px;
  }
  &__cards::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  &__card__nill {
    width: calc(20px);
    height: 12.5rem;
    // background: red;
    margin: auto;
    scroll-snap-align: center;
    flex-shrink: 0;
    margin: 0 0.3rem;
  }

  &__card,
  &__card__filler {
    //filter: drop-shadow(0px 25px 50px rgba(34, 34, 34, 0.12));
    scroll-snap-align: center;
    flex-shrink: 0;
    width: calc(100% - 70px);
    margin: auto;
    height: 12.5rem;
    border-radius: 1rem;
    background: linear-gradient(
      345deg,
      rgba(165, 126, 228, 0.87) 14.06%,
      #cb92b1 50.52%,
      rgba(203, 146, 177, 0.65) 80.21%
    );
    //mix-blend-mode: darken;
    //filter: drop-shadow(0px 25px 50px rgba(34, 34, 34, 0.12));
    //mix-blend-mode: multiply;
    padding: 1rem;
    margin: 0 0.3rem;

    &__heading {
      display: flex;
      justify-content: space-between;

      h3 {
        margin: 0;
        padding: 0;
        color: #422f54;
        font-family: Poppins;
        font-size: 0.975rem;
        font-style: normal;
        font-weight: 700;
      }
      img {
        width: 20px;
      }
      p {
        color: #1c213a;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
      }
    }

    &__reader {
      margin-top: 80px;
      img {
        height: 28px;
      }
    }

    &__filler {
      background: linear-gradient(
        132deg,
        #e8e6fc 0%,
        rgba(217, 214, 250, 0.46) 100%
      );
      display: grid;
      place-content: center;

      &__add {
        width: 2rem;
        height: 2rem;
        background: #4438cb;
        display: grid;
        place-content: center;
        cursor: pointer;
        border-radius: 50%;
        img {
          width: 15px;
        }
      }
    }
    &__details {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 10px;

      h3 {
        color: #1c213a;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
      }
      img {
        width: 40px;
      }
    }

    &__frozen {
      // &__blurred {
      position: relative;

      background: url("../../../assets/images/noisy-background\ 2.png"),
        lightgray 50% / cover no-repeat;
      transform: translate3d(0, 0, 0);
      //   mix-blend-mode: darken;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        backdrop-filter: blur(2.9px);
        -webkit-backdrop-filter: blur(2.9px);
        border-radius: 1rem;
        //opacity: 0.10000000149011612;
        background: url("../../../assets/images/noisy-background\ 2.png"),
          lightgray 50% / cover no-repeat;
        mix-blend-mode: multiply;
        top: 0;
        left: 0;
      }
      // }
    }
  }

  &__card {
    position: relative;
    background: url("../../../assets/images/noisy-background\ 2.png"),
      lightgray 50% / cover no-repeat;
    transform: translate3d(0, 0, 0);
    //margin-right: calc(70px + 0.4rem) !important;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 1rem;
      background: linear-gradient(
        345deg,
        rgba(165, 126, 228, 0.87) 14.06%,
        #cb92b1 50.52%,
        rgba(203, 146, 177, 0.65) 80.21%
      );
      mix-blend-mode: multiply;
      top: 0;
      left: 0;
    }
  }

  //   &__card__filler {
  //     background: linear-gradient(
  //       132deg,
  //       #e8e6fc 0%,
  //       rgba(217, 214, 250, 0.46) 100%
  //     );
  //   }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 100px);
    margin: auto;
    margin-top: 1.5rem;
  }
  &__action {
    cursor: pointer;
    &__icon {
      width: 2.4rem;
      height: 2.4rem;
      background: #fff;
      border-radius: 50%;
      display: grid;
      place-content: center;
      margin: auto;
      img {
        width: 17px;
        height: 17px;
      }
    }
    &__title {
      color: #1c213a;
      font-family: Inter;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 0.5rem;
      text-align: center;
    }
  }
  &__tags {
    margin: 1rem;
    margin-bottom: 0rem;
  }

  &__quickAccess {
    margin: 1rem;
    margin-bottom: 0rem;
  }

  &__transactions {
    border-radius: 1rem;
    background: #fff;
    min-height: 200px;
    margin: 1rem;
    margin-bottom: 0;
    margin-top: 1.5rem;
    padding: 1rem;
    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5rem;
      h3 {
        color: #8d8e92;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    &__content {
      margin-top: 1rem;
    }

    &__more {
      color: #4438cb;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      //   margin-bottom: 0.5rem;
      //   margin-top: 0.5rem;
    }

    &__empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 70px;
        margin-right: 1.2rem;
      }
      p {
        color: #1c213a;
        font-family: Inter;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.22rem;
      }
    }
  }
}
