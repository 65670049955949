.transactionFilter {
  // padding: 2rem 0;
  background: #f3f3f5;
  padding: 0;
  border-radius: 1.25rem;
  &__heading {
    padding: 2rem 1rem;
    background: #fff;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    h3 {
      color: #1c213a;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      margin-top: 0rem;
    }
    p {
      color: #1c213a;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__options {
    padding: 1rem;
    padding-bottom: 2rem;
  }
  &__option {
    background: #fff;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    padding: 1.5rem 1rem;
    cursor: pointer;
    p {
      color: #1c213a;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
 
}
