.quickAccess {
  &__heading {
    color: #8d8e92;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__items {
    margin-top: 1rem;
    display: flex;
    overflow: auto;
  }
  &__item {
    height: 50px;
    width: fit-content;
    padding: 0 1rem;
    background: #fff;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    p {
      font-size: 0.8rem;
      margin-left: 0.3rem;
      white-space: nowrap
    }
    img {
      width: 20px;
    }
  }
}
