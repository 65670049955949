.inputPin {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;

  input {
    width: 100%;
    max-width: 60px;
    height: 55px;
    padding: 0.5rem;
    font-size: 2rem;
    display: block;
    margin: auto;
    text-align: center;
    border-radius: 0.3125rem;
    background: #eff0f3;
    outline: 0;
    border: none;
    color: #000;
    font-weight: 600;
  }
}
