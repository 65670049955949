.forAndroid {
  background: #fff;
  min-height: 100vh;
  padding: 2rem 0rem;
  padding-bottom: 1rem;
  &__content {
    padding: 0 1rem;
    min-height: calc(100vh - 160px);
  }
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border: 1px solid #e8ebfb;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__mobile {
    margin-top: 1.5rem;

    &__heading {
      color: #1c213a;
      text-align: center;
      font-size: 0.83rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &__links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: #212121;
      border-radius: 100px;
      margin-top: 0.5rem;
      padding: 0rem;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 1rem 1rem;
      // padding: .1rem 0;
      // width: fit-content;
      // margin: auto;
      p {
        color: #fff;
        font-size: 0.8rem;
        margin-left: 0.5rem;
      }
      img {
        width: 13px;
      }
      &:first-child{
        img{
          transform: translateY(-1px);
        }
      }
      &:last-child {
        border-radius: 1.5625rem;
        background: #5e5e5e;
        img {
          width: 15px;
        }
      }
    }
  }

  &__steps {
    margin-top: 2rem;
  }

  &__step {
    margin-top: 1rem;
    &__text {
      display: grid;
      grid-template-columns: 50px 1fr;
    }
    &__number {
      display: grid;
      place-content: center;
      background: #e5e2ff;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      p {
        color: #4438cb;
        text-align: center;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
    }
    &__desc {
      p {
        color: #1c213a;
        font-size: 0.95rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      img {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
}
