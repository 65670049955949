.kyc {
  min-height: 100vh;
  background: #f3f3f5;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }

  &__loading {
    min-height: 100vh;
    display: grid;
    place-content: center;
    p {
      color: #1c213a;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__main {
    padding: 1rem;
    font-family: Inter;
    min-height: 100vh;
    &__heading {
      margin-top: 3rem;
      color: #1c213a;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
    }
    &__desc {
      color: #1c213a;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__submit {
      border-radius: 1.875rem;
      background: #4438cb;
      height: 3.2rem;
      width: 100%;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 5rem;
      font-style: normal;
      font-family: Inter;
      cursor: pointer;
      display: grid;
      place-content: center;
      @media (max-width: 350px) {
        font-size: 0.9rem;
      }

      background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
      z-index: 1;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        top: -5px;
        left: -5px;
        z-index: -1;
        border-radius: 3rem;
        background: linear-gradient(
          179deg,
          rgba(68, 56, 203, 0.25) 0%,
          rgba(112, 57, 203, 0.8) 100%
        );
        filter: blur(10px);
      }
    }
  }

  &__menu {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: grid;
    place-content: center;
    margin-left: auto;

    &__icon {
      width: 23px;
    }

    &__items {
      position: absolute;
      border-radius: 0.625rem;
      background: #fff;
      box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.15);
      padding: 1rem 1rem;
      padding-top: 0.5rem;
      right: 1rem;
      top: 1rem;
      min-width: 170px;

      animation: show-menu 0.2s ease-out 1;
      @keyframes show-menu {
        from {
          transform: scale(0.9);
        }
        to {
          transform: scale(1);
        }
      }
    }
    &__item {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;

      p {
        color: #a5a5a5;
        font-family: Inter;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 0.5rem;
      }
      img {
        width: 17px;
      }
      &:last-child {
        margin-top: 1.2rem;
        img {
          width: 19px;
        }
      }
    }
  }
}
