.beneficiaries {
  margin-top: 1rem;
  &__heading {
    color: #8d8e92;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__items {
    margin-top: 1rem;
    display: flex;
    overflow-x: auto;

    &__loading {
      height: auto;
      min-height: 30px;
      margin: auto;
      width: fit-content;
    }
  }

  &__item {
    background: #fff;
    padding: 0.7rem;
    width: 100px !important;
    min-width: 100px;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    &__profile {
      width: 40px;
      height: 40px;
      position: relative;
      margin: auto;
      border-radius: 50%;
      
    &__dummy {
        width: 40px;
        height: 40px;
        display: grid;
        place-content: center;
        border-radius: 50%;
        background: #ecebfa;
        h3 {
          color: #4438cb !important;
          font-size: .8rem !important;
          font-style: normal;
          font-weight: 700 !important;
          line-height: normal;
          margin: 0 !important;
          padding: 0 !important;
        }
      }
  

      &__bank {
        width: 40px;
        height: 40px;

        border-radius: 50%;
      }
      &__country {
        position: absolute;
        right: -5px;
        bottom: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }

    h3 {
      color: #585858;
      font-family: Inter;
      font-size: 0.65rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      word-wrap: wrap;
      text-align: center;
      margin-top: 0.5rem;
    }
    p {
      color: #8d8e92;
      font-family: Inter;
      font-size: 0.65rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin-top: 0.3rem;
    }
  }
}
