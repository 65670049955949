.receive {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 0rem;
  padding-bottom: 1rem;
  &__content {
    padding: 0 1rem;
    min-height: calc(100vh - 160px);
  }
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__destinations {
    margin-top: 2rem;
  }

  &__destination {
    border-radius: 1rem;
    background: #fff;
    display: grid;
    grid-template-columns: 45px 1fr;
    padding: 1rem 1.5rem;
    margin-top: 0.3rem;
    cursor: pointer;
    img {
      width: 2.1rem;
    }
    &__blurred {
      opacity: 0.6;
    }
    &__content {
      h4 {
        color: #1c213a;
        font-family: Inter;
        font-size: 0.84rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        opacity: 0.800000011920929;
      }
      p {
        color: #8d8e92;
        font-family: Inter;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.2rem;
      }
    }
  }
}
