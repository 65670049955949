.referrals {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 1rem;

  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }

  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }

  &__desc {
    margin-top: 0.2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__items {
    margin-top: 2rem;
  }

  &__item {
    min-height: 50px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    &:nth-child(even) {
      background: #80808009;
    }
    button {
      border-radius: 20px;
      font-family: inherit;
      padding: 0.1rem 0.5rem;
      margin-top: 0.2rem;
      text-transform: capitalize;
      margin-top: 1rem;
    }
    h3 {
      color: #1c213a;
      font-family: Inter;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
    }
    p {
      color: #1c213a;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
 
  &__empty {
    min-height: 200px;
    display: grid;
    grid-template-columns: 1fr;
    place-content: center;
    padding: 0 1.5rem;
    padding-bottom: 1.5rem;

    &__content {
      width: fit-content;
      margin: auto;
    }
    img {
      width: 80px;
      display: block;
      // margin-left: 20px;
      margin: auto;
    }
    p {
      margin-top: 0.5rem;
      color: #7c7c80;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
  }
}
