.receiveDirectDebit {
  padding: 3rem 1.5rem;
  border-radius: 1.25rem;
  &__heading {
    color: #1c213a;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0rem;
    text-align: center;
    color: tomato;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 0.3rem;
  }

  &__actions {
    margin-top: 2rem;
  }

  &__submit {
    border-radius: 1.875rem;
    background: #4438cb;
    border: 1px solid #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    @media (max-width: 350px) {
      font-size: 0.9rem;
    }
    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }

  &__cancel {
    border-radius: 1.875rem;
    background: transparent;
    border: 1px solid #4438cb;
    height: 3.2rem;
    width: 100%;
    color: #4438cb;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    @media (max-width: 350px) {
      font-size: 0.9rem;
    }
  }
}
