.failed {
  padding: 1rem;
  min-height: 100vh;
  display: grid;
  place-content: center;
  background: linear-gradient(180deg, #e2dfff 19.79%, #f3f2ff 100%);
  img {
    width: 100px;
    margin: auto;
    display: block;
  }
  h3 {
    text-align: center;
    color: #1c213a;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    font-family: Inter;
    max-width: 350px;
    margin-top: 0.2rem;
    color: #1c213a;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  button {
    border-radius: 1.875rem;
    background: #4438cb;
    height: 3.2rem;
    min-width: 200px;
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 2.5rem;
    font-style: normal;
    font-family: Inter;
    cursor: pointer;
    display: grid;
    place-content: center;
    @media (max-width: 350px) {
      font-size: 0.9rem;
    }

    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }
}
