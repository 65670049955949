.bvn {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 1rem;
  padding-bottom: 4rem;
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__input{
    margin-top: 2rem;


    
  }



  &__submit {
    width: 100%;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    height: 50px;
    display: grid;
    place-content: center;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 5rem;
    z-index: 2;
    border-radius: 2.5rem;
    background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      z-index: -1;
      border-radius: 3rem;
      background: linear-gradient(
        179deg,
        rgba(68, 56, 203, 0.25) 0%,
        rgba(112, 57, 203, 0.8) 100%
      );
      filter: blur(10px);
    }
  }
}
