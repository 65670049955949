.cardDetails {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 1rem;

  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }

  &__select {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    &__content {
      min-width: 150px;
      height: 30px;
      margin: 0 0.5rem;
      border-radius: 1.25rem;
      border: 1px solid rgba(28, 33, 58, 0.15);
      background: #fff;
      display: grid;
      place-content: center;

      p {
        color: #1c213a;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0 1rem;
      }
    }
  }

  &__cards {
    margin-top: 1rem;
  }
  &__cards::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &__card {
    //filter: drop-shadow(0px 25px 50px rgba(34, 34, 34, 0.12));
    scroll-snap-align: center;
    flex-shrink: 0;
    width: calc(100% - 40px);
    margin: auto;
    height: 12.5rem;
    border-radius: 1rem;
    padding: 1rem;
    position: relative;
    background: url("../../../assets/images/noisy-background\ 2.png"),
      lightgray 50% / cover no-repeat;
    transform: translate3d(0, 0, 0);
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 1rem;
      background: linear-gradient(
        345deg,
        rgba(165, 126, 228, 0.87) 14.06%,
        #cb92b1 50.52%,
        rgba(203, 146, 177, 0.65) 80.21%
      );
      mix-blend-mode: multiply;
      top: 0;
      left: 0;
    }

    &__heading {
      display: flex;
      justify-content: space-between;

      h3 {
        margin: 0;
        padding: 0;
        color: #422f54;
        font-family: Poppins;
        font-size: 0.975rem;
        font-style: normal;
        font-weight: 700;
      }
      img{
        width: 20px;
      }
      p {
        color: #1c213a;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
      }
    }

    &__reader {
      margin-top: 80px;
      img {
        height: 28px;
      }
    }
    &__details {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 10px;

      h3 {
        color: #1c213a;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
      }
      img {
        width: 40px;
      }
    }
  }
  &__details {
    margin-top: 4rem;
  }
  &__detail {
    border-radius: 1rem;
    background: #fff;
    min-height: 50px;
    margin-top: 1rem;
    margin-bottom: 0;
    margin-top: 0.5rem;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 40px;
    &__heading {
      color: #8d8e92;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &__content {
      margin-top: 0.5rem;
      color: #1c213a;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &__copy {
      display: grid;
      place-content: center;
    }
    img {
      width: 20px;
    }
  }
}
