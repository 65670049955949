* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-decoration: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  overflow-x: hidden;
  height: auto;
  scroll-behavior: smooth !important ;
}

html {
  scroll-behavior: smooth;

}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  box-decoration-break: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  overflow-wrap: break-word !important;
  font-family: $font-default;
  font-weight: normal;
  font-style: normal;
  color: #000;
  -webkit-tap-highlight-color: transparent;
}

a{
  -webkit-tap-highlight-color: transparent;
}

button {
  border: 0;
  outline: none;
  text-decoration: none;
}

::-webkit-scrollbar-track {
  border: 0px solid black;
  background-color: transparent;
  //background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
  @media (max-width: 480px) {
    width: 5px;
    height: 5px;
  }
  // margin-right:10rem;
  //	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}







.Toastify__toast--error {
  border-left: 4px solid #FF0202;
  border-radius: 4px !important;
  background: #FDDCDC !important;
}
.Toastify__toast--error::after {
//content : url('../assets/images/svg/closeToast.svg'); // Your svg Path
position: absolute;
color: #333333;
font-size: 15px;
font-weight: 700;
left:265px;
padding-top: 14px !important;
}
.Toastify__toast--error::before {
//content: url("../assets/images/svg/errorToast.svg");// Your svg Path
position:relative; 
z-index:100000;
left:12px;
top:6px;
}
.Toastify__toast--success {
border-left: 4px solid green !important;
border-radius: 4px !important;
background: #F0F9FA !important;
}
.Toastify__toast--success::before {
//content: url("../assets/images/svg/successToast.svg");// Your svg Path
position:relative; 
z-index:100000;
left:12px;
top:6px;
}
.Toastify__toast--success::after {
//content : url('../assets/images/svg/closeToast.svg');// Your svg Path
position: absolute;
color: #333333;
font-size: 15px;
font-weight: 700;
left:265px;
padding-top: 14px !important;
}
.Toastify__toast--warning {
border-left: 4px solid #E78326  !important;
border-radius: 4px !important;
background: #FADFC5 !important;
}
.Toastify__toast--warning::before {
//content: url("../assets/images/svg/warnToast.svg");// Your svg Path
position:relative; 
z-index:100000;
left:12px;
top:6px;
}  
.Toastify__toast--warning::after {
//content : url('../assets/images/svg/closeToast.svg'); // Your svg Path
position: absolute;
color: #E78326;
font-size: 15px;
font-weight: 700;
left:265px;
padding-top: 14px !important;
}
.Toastify__toast-body {
color: #444C63    ;
font-size: 16px;
padding-left: 10px;
line-height: 20px;
padding: 0px;
padding-top: 25px;
width: 100%;
font-weight: 400;
margin-left: 5px !important;
} 
.Toastify__toast > button>  svg {
  color: #1B1B1B;
}

