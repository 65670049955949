.sendForm {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 1rem;
  padding-bottom: 4rem;
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }
  &__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__transfer__desc {
    color: #1c213a;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1.5rem;
  }
  &__toggle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 1rem;

    &__item {
      border-radius: 0.375rem;
      border: 1px solid #e8ebfb;
      background: #fff;
      display: grid;
      place-content: center;
      height: 80px;
      padding: 1rem;
      position: relative;
      cursor: pointer;
      p {
        color: #a5a5a5;
        text-align: center;
        font-size: 0.95rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &__active {
        border: 1px solid #4438cb;
        p {
          color: #4438cb;
        }
      }
      &__dot__active {
        background: #b5b9d3;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;

        &::after {
          z-index: 2;
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          left: 2px;
          top: 2px;
          background: #fff;
          border-radius: 50%;
        }
      }

      &__dot {
        background: #b5b9d3;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;

        &::after {
          z-index: 2;
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          left: 3px;
          top: 3px;
          background: #4438cb;
          border-radius: 50%;
        }
      }
    }
  }
  &__authorization {
    &__empty {
      min-height: 200px;
      display: grid;
      grid-template-columns: 1fr;
      place-content: center;
      padding: 0 1.5rem;
      padding-bottom: 1.5rem;
      margin: 2rem 0;

      &__content {
        width: fit-content;
        margin: auto;
      }
      img {
        width: 80px;
        display: block;
        // margin-left: 20px;
        margin: auto;
      }
      p {
        margin-top: 0.5rem;
        color: #7c7c80;
        font-family: Inter;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
    }
  }
  &__form {
    margin-top: 2rem;

    &__data {
      margin-top: 1rem;
      color: #8d8e92;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &__item {
      margin-top: 1.5rem;
      label {
        color: #494b55;
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: inherit;
        display: block;
        padding-bottom: 0.3rem;
      }
      p {
        color: #8d8e92;
        text-align: right;
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.3rem;
      }

      &__input {
        height: 50px;
        width: 100%;
        outline: none;
        font-size: 1rem;
        padding: 0 1rem;
        border-radius: 0.3125rem;
        background: #e5e8ef;
        border: none;
      }
    }

    &__submit {
      cursor: pointer;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      height: 50px;
      display: grid;
      place-content: center;
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: 5rem;
      z-index: 2;
      border-radius: 2.5rem;
      background: linear-gradient(179deg, #4438cb 0%, #7039cb 100%);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        top: -5px;
        left: -5px;
        z-index: -1;
        border-radius: 3rem;
        background: linear-gradient(
          179deg,
          rgba(68, 56, 203, 0.25) 0%,
          rgba(112, 57, 203, 0.8) 100%
        );
        filter: blur(10px);
      }
    }
  }
}
