.transactions {
  background: #f3f3f5;
  min-height: 100vh;
  padding: 2rem 1rem;
  &__back {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    img {
      height: 13px;
      margin-left: -10%;
    }
  }
  &__heading {
    margin-top: 2rem;
    color: #1c213a;
    font-family: Inter;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    &__search {
      background: pink;
      width: calc(100% - 3rem);
      border-radius: 1.25rem;
      border: 1px solid rgba(28, 33, 58, 0.1);
      background: #fff;
      height: 2.4rem;
      display: grid;
      align-items: center;
      grid-template-columns: 40px 1fr;
      input {
        width: 100%;
        height: 100%;
        border-top-right-radius: 1.25rem;
        border-bottom-right-radius: 1.25rem;
        outline: 0;
        border: 0;
        padding-right: 1rem;
        font-size: 0.9rem;
      }
      img {
        margin-left: 1rem;
        width: 20px;
      }
    }
    &__filter {
      background: #4438cb;
      height: 2.4rem;
      width: 2.4rem;
      display: grid;
      place-content: center;
      border-radius: 50%;
      cursor: pointer;
      img {
        width: 18px;
      }
    }
  }
  &__group {
    border-radius: 1rem;
    background: #fff;
    min-height: 100px;
    margin-bottom: 0;
    margin-top: 1.5rem;
    padding: 1rem;

    &__empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 70px;
        margin-right: 1.2rem;
      }
      p {
        color: #1c213a;
        font-family: Inter;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0.22rem;
      }
    }
    &__heading {
      color: #8d8e92;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &__content {
      margin-top: 1rem;
    }
  }
}
